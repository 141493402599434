"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
export default function GlobalError({
  error
}: {
  error: Error;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <Error statusCode={undefined as any} data-sentry-element="Error" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx" />;
}